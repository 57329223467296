// Node modules.
import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';

const AuthRoute = ({
  account, component, exact, path,
}) => {
  if (isEmpty(account)) {
    return <Redirect to='/login' />;
  }

  return <Route exact={exact} path={path} component={component} />;
};

AuthRoute.propTypes = {
  component: PropTypes.func.isRequired,
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
  // From mapStateToProps.
  account: PropTypes.object,
};

const mapStateToProps = (state) => ({
  account: state.accountReducer.account,
});

export default connect(mapStateToProps, null)(AuthRoute);
