import {
  CREATE_ACCOUNT,
  CREATE_ACCOUNT_FAILURE,
  CREATE_ACCOUNT_SUCCESS,
  GET_ACCOUNT,
  GET_ACCOUNT_FAILURE,
  GET_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT,
  UPDATE_ACCOUNT_FAILURE,
  UPDATE_ACCOUNT_SUCCESS,
} from './constants';

// ====================
// CREATE
// ====================
export const createAccountAction = (account) => ({
  account,
  type: CREATE_ACCOUNT,
});

export const createAccountFailure = (error) => ({
  error,
  type: CREATE_ACCOUNT_FAILURE,
});

export const createAccountSuccess = (account) => ({
  account,
  type: CREATE_ACCOUNT_SUCCESS,
});

// ====================
// GET INDEX (MULTIPLE)
// ====================
export const getAccountAction = () => ({
  type: GET_ACCOUNT,
});

export const getAccountFailure = (error) => ({
  error,
  type: GET_ACCOUNT_FAILURE,
});

export const getAccountSuccess = (account) => ({
  account,
  type: GET_ACCOUNT_SUCCESS,
});

// ====================
// UPDATE
// ====================
export const updateAccountAction = (account) => ({
  account,
  type: UPDATE_ACCOUNT,
});

export const updateAccountFailure = (error) => ({
  error,
  type: UPDATE_ACCOUNT_FAILURE,
});

export const updateAccountSuccess = (account) => ({
  account,
  type: UPDATE_ACCOUNT_SUCCESS,
});
