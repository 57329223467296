// Node modules.
import styled from 'styled-components';
// Relative imports.
import Close from 'assets/react-svgs/Close';

export const Wrapper = styled.div`
  align-items: center;
  background: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  left: 0;
  line-height: 1.5;
  overflow: hidden;
  padding: 20px 30px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;

  @media (max-width: 880px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const StyledRow = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  @media (max-width: 880px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const CloseIcon = styled(Close)`
  flex-shrink: 0;
  margin: 0 -10px 0 15px;
  width: 20px;

  g,
  path {
    stroke: #666666;
  }

  @media (max-width: 880px) {
    margin: 10px 0 0;
  }
`;

export const Message = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 880px) {
    text-align: center;
  }

`;

export const Action = styled.div`
  color: #666666;
  font-weight: 600;
  margin: 0 0 0 10px;
  transition: color 0.5s ease;

  &:hover {
    color: #333333;
  }

  @media (max-width: 880px) {
    margin: 10px 0 0;
  }
`;
