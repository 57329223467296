// Node modules.
import { lazy } from 'react';
// Relative imports.
const AdminCreateBeverage = lazy(() => import('components/AdminCreateBeverage'));
const AdminEditBeverage = lazy(() => import('components/AdminEditBeverage'));
const AdminDeleteBeverage = lazy(() => import('components/AdminDeleteBeverage'));
const CreateAccountBeverage = lazy(() => import('components/CreateAccountBeverage'));
const CreateBeverage = lazy(() => import('components/CreateBeverage'));
const DeleteAccountBeverage = lazy(() => import('components/DeleteAccountBeverage'));
const UpdateAccountBeverage = lazy(() => import('components/UpdateAccountBeverage'));

// Export modalIDs.
export const ADMIN_CREATE_BEVERAGE = 'ADMIN_CREATE_BEVERAGE';
export const ADMIN_EDIT_BEVERAGE = 'ADMIN_EDIT_BEVERAGE';
export const ADMIN_DELETE_BEVERAGE = 'ADMIN_DELETE_BEVERAGE';
export const CREATE_ACCOUNT_BEVERAGE = 'CREATE_ACCOUNT_BEVERAGE';
export const CREATE_BEVERAGE = 'CREATE_BEVERAGE';
export const DELETE_ACCOUNT_BEVERAGE = 'DELETE_ACCOUNT_BEVERAGE';
export const UPDATE_ACCOUNT_BEVERAGE = 'UPDATE_ACCOUNT_BEVERAGE';

// Export modalsLookup
export const MODALS_LOOKUP = {
  [ADMIN_CREATE_BEVERAGE]: {
    Content: AdminCreateBeverage,
  },
  [ADMIN_EDIT_BEVERAGE]: {
    Content: AdminEditBeverage,
  },
  [ADMIN_DELETE_BEVERAGE]: {
    Content: AdminDeleteBeverage,
  },
  [CREATE_ACCOUNT_BEVERAGE]: {
    Content: CreateAccountBeverage,
  },
  [DELETE_ACCOUNT_BEVERAGE]: {
    Content: DeleteAccountBeverage,
  },
  [UPDATE_ACCOUNT_BEVERAGE]: {
    Content: UpdateAccountBeverage,
  },
  [CREATE_BEVERAGE]: {
    Content: CreateBeverage,
  },
};
