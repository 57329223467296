export const LOGIN = 'Auth/LOGIN';
export const LOGIN_FAILURE = 'Auth/LOGIN_FAILURE';
export const LOGIN_SUCCESS = 'Auth/LOGIN_SUCCESS';
export const LOGIN_WITH_PROVIDER = 'Auth/LOGIN_WITH_PROVIDER';
export const LOGIN_WITH_PROVIDER_FAILURE = 'Auth/LOGIN_WITH_PROVIDER_FAILURE';
export const LOGIN_WITH_PROVIDER_SUCCESS = 'Auth/LOGIN_WITH_PROVIDER_SUCCESS';
export const LOGOUT = 'Auth/LOGOUT';
export const REGISTER_AUTH_STATE_CHANGE = 'Auth/REGISTER_AUTH_STATE_CHANGE';
export const SEND_EMAIL_VERIFICATION = 'Auth/SEND_EMAIL_VERIFICATION';
export const SEND_EMAIL_VERIFICATION_FAILURE = 'Auth/SEND_EMAIL_VERIFICATION_FAILURE';
export const SEND_EMAIL_VERIFICATION_SUCCESS = 'Auth/SEND_EMAIL_VERIFICATION_SUCCESS';
export const SEND_PASSWORD_RESET_EMAIL = 'Auth/SEND_PASSWORD_RESET_EMAIL';
export const SEND_PASSWORD_RESET_EMAIL_FAILURE = 'Auth/SEND_PASSWORD_RESET_EMAIL_FAILURE';
export const SEND_PASSWORD_RESET_EMAIL_SUCCESS = 'Auth/SEND_PASSWORD_RESET_EMAIL_SUCCESS';
