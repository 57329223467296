// Node modules.
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import get from 'lodash/get';

export const registerApi = async (account) => {
  // Derive the firebase auth.
  const auth = firebase.auth();

  // Only send email and password.
  const email = get(account, 'email');
  const password = get(account, 'password');
  const displayName = get(account, 'displayName');

  // Create the current user.
  await auth.createUserWithEmailAndPassword(email, password);

  // Update the display name and photoUri.
  await auth.currentUser.updateProfile({
    displayName,
    photoURL:
      'https://firebasestorage.googleapis.com/v0/b/homebar-web--dev.appspot.com/o/public%2Fimages%2FdefaultUserIcon.png?alt=media&token=8c49d6ce-eb27-4633-b549-1bc055785cc9',
  });
};
