export const ADMIN_CREATE_BEVERAGE = 'Beverages/ADMIN_CREATE_BEVERAGE';
export const ADMIN_EDIT_BEVERAGE = 'Beverages/ADMIN_EDIT_BEVERAGE';
export const ADMIN_EDIT_BEVERAGE_SUCCESS = 'Beverages/ADMIN_EDIT_BEVERAGE_SUCCESS';
export const ADMIN_EDIT_BEVERAGE_FAILURE = 'Beverages/ADMIN_EDIT_BEVERAGE_FAILURE';
export const CREATE_BEVERAGE = 'Beverages/CREATE_BEVERAGE';
export const CREATE_BEVERAGE_FAILURE = 'Beverages/CREATE_BEVERAGE_FAILURE';
export const CREATE_BEVERAGE_SUCCESS = 'Beverages/CREATE_BEVERAGE_SUCCESS';
export const FETCH_BEVERAGE_PURCHASE_PRICES_LOOKUP =
  'Beverages/FETCH_BEVERAGE_PURCHASE_PRICES_LOOKUP';
export const FETCH_BEVERAGE_PURCHASE_PRICES_LOOKUP_FAILURE =
  'Beverages/FETCH_BEVERAGE_PURCHASE_PRICES_LOOKUP_FAILURE';
export const FETCH_BEVERAGE_PURCHASE_PRICES_LOOKUP_SUCCESS =
  'Beverages/FETCH_BEVERAGE_PURCHASE_PRICES_LOOKUP_SUCCESS';
export const SEED_BEVERAGES = 'Beverages/SEED_BEVERAGES';
export const SEED_BEVERAGES_FAILURE = 'Beverages/SEED_BEVERAGES_FAILURE';
export const SEED_BEVERAGES_SUCCESS = 'Beverages/SEED_BEVERAGES_SUCCESS';
export const SELECT_BEVERAGE = 'Beverages/SELECT_BEVERAGE';
export const REFETCH_BEVERAGE = 'Beverages/REFETCH_BEVERAGE';
export const REFETCH_BEVERAGE_COMPLETE = 'Beverages/REFETCH_BEVERAGE_COMPLETE';
