export default [
  'addedAt',
  'deleted',
  'imageURL',
  'purchaseLocation',
  'purchasePriceCents',
  'quantity',
  'rating',
  'review',
  'storePick',
  'volumeML',
];
