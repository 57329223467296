// Node modules.
import { combineReducers } from 'redux';
// Relative imports.
import accountBeverageReducer from 'containers/AccountBeverage/reducer';
import accountReducer from 'containers/Account/reducer';
import authReducer from 'containers/Auth/reducer';
import beveragesReducer from 'containers/Beverages/reducer';
import modalReducer from 'containers/Modal/reducer';
import registerReducer from 'containers/Register/reducer';
import universalNotificationsReducer from 'containers/UniversalNotifications/reducer';

const allReducers = combineReducers({
  accountBeverageReducer,
  accountReducer,
  authReducer,
  beveragesReducer,
  modalReducer,
  registerReducer,
  universalNotificationsReducer,
});

export default allReducers;
