// Node modules.
import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import get from 'lodash/get';
// Relative imports.
import history from 'store/history';
import { SUCCESS } from 'containers/UniversalNotifications/MESSAGE_TYPES';
import { handleSagaError } from 'utils/sagaHelpers';
import { showUniversalNotificationAction } from 'containers/UniversalNotifications/actions';
import { updateModalIDAction } from 'containers/Modal/actions';
import { CREATE_ACCOUNT_BEVERAGE } from 'containers/Modal/MODALS';
import { notifySlack } from 'utils/slack';
import {
  ADMIN_CREATE_BEVERAGE,
  ADMIN_EDIT_BEVERAGE,
  CREATE_BEVERAGE,
  FETCH_BEVERAGE_PURCHASE_PRICES_LOOKUP,
  SEED_BEVERAGES,
} from './constants';
import {
  adminCreateBeverageApi,
  createBeverageApi,
  fetchBeveragePurchasePricesLookupApi,
  seedBeveragesApi,
  adminEditBeverageApi,
} from './api';
import {
  createBeverageFailure,
  createBeverageSuccess,
  adminEditBeverageSuccess,
  adminEditBeverageFailure,
  fetchBeveragePurchasePricesLookupSuccess,
  fetchBeveragePurchasePricesLookupFailure,
  seedBeveragesFailure,
  seedBeveragesSuccess,
  selectBeverageAction,
  refetchBeverage,
} from './actions';

function* adminCreateBeverageSaga({ beverage }) {
  let newBeverage;
  try {
    // Attempt to create the beverage.
    newBeverage = yield call(adminCreateBeverageApi, beverage);

    yield put(createBeverageSuccess(newBeverage.name));
    yield put(refetchBeverage());
    yield put(
      showUniversalNotificationAction({
        message: 'Successfully added a bottle to the Library',
        messageType: SUCCESS,
      }),
    );
  } catch (error) {
    yield call(handleSagaError, error, {
      actionCreators: [createBeverageFailure],
    });
  }

  const name = get(newBeverage, 'name');
  const beverageID = get(newBeverage, 'id');
  const imageURL = get(newBeverage, 'imageURL');

  yield all([put(selectBeverageAction(beverageID)), put(updateModalIDAction(undefined))]);

  // Navigate to the beverage detail page.
  history.push(`/beverages/${beverageID}`);

  // Derive the account properties.
  const account = yield select((state) => state.accountReducer.account);
  const firstName = get(account, 'firstName');
  const lastName = get(account, 'lastName');
  const accountID = get(account, 'id');

  // Construct the create beverage request slack message.
  const slackMessage = `${firstName} ${lastName} (accountID: ${accountID}) just used admin priveleges to create a new beverage called:\n*${name}* (beverageID: *${beverageID}*) No review necessary!`;

  // Attempt to notify slack.
  notifySlack(slackMessage, '#create-beverage-requests', {
    iconURL: imageURL,
  });
}

export function* adminEditBeverageSaga({ beverage }) {
  try {
    yield call(adminEditBeverageApi, beverage);
    yield all([
      put(adminEditBeverageSuccess(beverage)),
      put(refetchBeverage()),
      put(
        showUniversalNotificationAction({
          message: get(beverage, 'deleted')
            ? 'Successfully removed from Library!'
            : 'Successfully updated in Library!',
          messageType: SUCCESS,
        }),
      ),
      put(updateModalIDAction()),
    ]);
  } catch (error) {
    yield call(handleSagaError, error, {
      actionCreators: [adminEditBeverageFailure],
    });
  }
}

function* createBeverageSaga({ beverage, options }) {
  // Derive options' properties.
  const showCreateAccountBeverage = get(options, 'showCreateAccountBeverage');

  let newBeverage;
  try {
    // Attempt to create the beverage.
    newBeverage = yield call(createBeverageApi, beverage);
    yield put(createBeverageSuccess(newBeverage.name));
    yield put(
      showUniversalNotificationAction({
        message: 'Successfully submitted the bottle to Homebar!',
        messageType: SUCCESS,
      }),
    );
  } catch (error) {
    yield call(handleSagaError, error, {
      actionCreators: [createBeverageFailure],
    });
  }

  const name = get(newBeverage, 'name');
  const beverageID = get(newBeverage, 'id');
  const imageURL = get(newBeverage, 'imageURL');

  // Show the success message + refetch beverages + close modal (or show create acct bev modal).
  yield all([
    put(selectBeverageAction(beverageID)),
    put(updateModalIDAction(showCreateAccountBeverage ? CREATE_ACCOUNT_BEVERAGE : undefined)),
  ]);

  // Navigate to the beverage detail page.
  history.push(`/beverages/${beverageID}`);

  // Derive the account properties.
  const account = yield select((state) => state.accountReducer.account);
  const firstName = get(account, 'firstName');
  const lastName = get(account, 'lastName');
  const accountID = get(account, 'id');

  // Construct the create beverage request slack message.
  const firestoreLink =
    'https://console.firebase.google.com/u/1/project/homebar-web--dev/firestore/data~2Fbeverages';
  const slackMessage = `${firstName} ${lastName} (accountID: ${accountID}) just created a new beverage called:\n*${name}* (beverageID: *${beverageID}*)${
    showCreateAccountBeverage ? ' and is adding it to their inventory' : ''
  }! <${firestoreLink}|Click here> to start reviewing it.`;

  // Attempt to notify slack.
  notifySlack(slackMessage, '#create-beverage-requests', {
    iconURL: imageURL,
  });
}

function* fetchBeveragePurchasePricesLookupSaga({ beverageID }) {
  try {
    const beveragePurchasePricesLookup = yield call(
      fetchBeveragePurchasePricesLookupApi,
      beverageID,
    );
    yield put(fetchBeveragePurchasePricesLookupSuccess(beveragePurchasePricesLookup));
  } catch (error) {
    yield call(handleSagaError, error, {
      actionCreators: [fetchBeveragePurchasePricesLookupFailure],
    });
  }
}

function* seedBeveragesSaga() {
  try {
    yield call(seedBeveragesApi);
    yield all([
      put(seedBeveragesSuccess()),
      put(
        showUniversalNotificationAction({
          message: 'Successfully seeded all beverages',
          messageType: SUCCESS,
        }),
      ),
    ]);
  } catch (error) {
    yield call(handleSagaError, error, {
      actionCreators: [seedBeveragesFailure],
    });
  }
}

export default [
  takeLatest(ADMIN_CREATE_BEVERAGE, adminCreateBeverageSaga),
  takeLatest(ADMIN_EDIT_BEVERAGE, adminEditBeverageSaga),
  takeLatest(CREATE_BEVERAGE, createBeverageSaga),
  takeLatest(FETCH_BEVERAGE_PURCHASE_PRICES_LOOKUP, fetchBeveragePurchasePricesLookupSaga),
  takeLatest(SEED_BEVERAGES, seedBeveragesSaga),
];
