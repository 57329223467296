// Node modules.
import { call, put, takeLatest } from 'redux-saga/effects';
// Relative imports.
import history from 'store/history';
import { SUCCESS } from 'containers/UniversalNotifications/MESSAGE_TYPES';
import { handleSagaError } from 'utils/sagaHelpers';
import { showUniversalNotificationAction } from 'containers/UniversalNotifications/actions';
import { GET_ACCOUNT, CREATE_ACCOUNT, UPDATE_ACCOUNT } from './constants';
import { createAccountApi, getAccountApi, updateAccountApi } from './api';
import {
  createAccountFailure,
  createAccountSuccess,
  getAccountAction,
  getAccountFailure,
  getAccountSuccess,
  updateAccountFailure,
  updateAccountSuccess,
} from './actions';

export function* createAccountSaga({ account }) {
  try {
    yield call(createAccountApi, account);
    yield put(createAccountSuccess({ ...account, password: undefined }));
    yield put(getAccountAction());
  } catch (error) {
    yield call(handleSagaError, error, {
      actionCreators: [createAccountFailure],
    });
  }
}

export function* getAccountSaga() {
  try {
    // Fetch the account.
    const account = yield call(getAccountApi);
    yield put(getAccountSuccess(account));

    // Redirect to the Discover page.
    yield call(history.push, '/inventory');
  } catch (error) {
    yield call(handleSagaError, error, {
      actionCreators: [getAccountFailure],
      skipUniversalNotification: true,
    });
  }
}

export function* updateAccountSaga({ account }) {
  try {
    // Make request to update account.
    const updatedAccount = yield call(updateAccountApi, account);
    yield put(updateAccountSuccess(updatedAccount));

    // Show success notification if the option is passed.

    yield put(
      showUniversalNotificationAction({
        message: 'Account updated successfully!',
        messageType: SUCCESS,
      }),
    );
  } catch (error) {
    yield call(handleSagaError, error, {
      actionCreators: [updateAccountFailure],
    });
  }
}

export default [
  takeLatest(CREATE_ACCOUNT, createAccountSaga),
  takeLatest(GET_ACCOUNT, getAccountSaga),
  takeLatest(UPDATE_ACCOUNT, updateAccountSaga),
];
