// Node modules.
import React, { Component, Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Router, Switch, Redirect, Route,
} from 'react-router-dom';
// Relative imports.
import AuthRoute from 'components/AuthRoute';
import LoadingPage from 'pages/LoadingPage';
import LoadingRoute from 'components/LoadingRoute';
import Modal from 'containers/Modal';
import UnauthRoute from 'components/UnauthRoute';
import UniversalNotifications from 'containers/UniversalNotifications';
import history from 'store/history';
import { Page } from './styles';
import { appInitAction } from './actions';

// Lazy load routes.
const Beverage = lazy(() => import('pages/Beverage'));
const Discover = lazy(() => import('pages/Discover'));
const Inventory = lazy(() => import('pages/Inventory'));
const Login = lazy(() => import('pages/Login'));
const Profile = lazy(() => import('pages/Profile'));
const Register = lazy(() => import('pages/Register'));
const ResetPassword = lazy(() => import('pages/ResetPassword'));

class Routes extends Component {
  componentDidMount() {
    // Make all of our app init calls.
    this.props.appInit({ initFirebase: true });
  }

  render() {
    const { modalID } = this.props;

    return (
      <Router history={history}>
        <main>
          {/* Common components */}
          <UniversalNotifications />
          <Modal />

          <Page
            style={{
              overflow: modalID ? 'hidden' : 'unset',
              maxHeight: modalID ? '100vh' : 'unset',
            }}
          >
            <Suspense fallback={<LoadingPage />}>
              <Switch>
                {/* Unprotected routes */}
                <Route
                  path='/'
                  exact
                  component={(props) => <LoadingRoute {...props} />}
                />
                <UnauthRoute
                  path='/login'
                  exact
                  component={(props) => <Login {...props} />}
                />
                <UnauthRoute
                  path='/reset-password'
                  component={(props) => <ResetPassword {...props} />}
                />
                <UnauthRoute
                  path='/register'
                  component={(props) => <Register {...props} />}
                />

                {/* Protected routes */}
                <AuthRoute
                  path='/beverages/:beverageID'
                  component={(props) => <Beverage {...props} />}
                />
                <AuthRoute
                  path='/discover'
                  component={(props) => <Discover {...props} />}
                />
                <AuthRoute
                  path='/inventory'
                  component={(props) => <Inventory {...props} />}
                />
                <AuthRoute
                  path='/profile'
                  component={(props) => <Profile {...props} />}
                />

                {/* Catch-all redirect */}
                <Redirect to='/login' />
              </Switch>
            </Suspense>
          </Page>
        </main>
      </Router>
    );
  }
}

Routes.propTypes = {
  // From mapStateToProps.
  modalID: PropTypes.string,
  // From mapDispatchToProps.
  appInit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  modalID: state.modalReducer.id,
});

const mapDispatchToProps = (dispatch) => ({
  appInit: (options) => dispatch(appInitAction(options)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
