// Node modules.
import get from 'lodash/get';

export const WEBHOOK_URL = 'https://hooks.slack.com/services/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';

export const notifySlack = async (text, channel = '#general', options) => {
  // Escape early if no webhookURL was provided.
  if (!WEBHOOK_URL) {
    return;
  }

  // Escape early if no message was provided.
  if (!text) {
    return;
  }

  // Derive options.
  const iconURL = get(options, 'iconURL');

  // @NOTE: No error handling here so as not to surface this to the user.
  await fetch(WEBHOOK_URL, {
    method: 'POST',
    body: JSON.stringify({
      channel,
      icon_url: iconURL,
      text,
      username: 'app.homebar.io Slack Bot',
    }),
  });
};
