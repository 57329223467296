import {
  ADMIN_CREATE_BEVERAGE,
  ADMIN_EDIT_BEVERAGE,
  ADMIN_EDIT_BEVERAGE_SUCCESS,
  ADMIN_EDIT_BEVERAGE_FAILURE,
  CREATE_BEVERAGE,
  CREATE_BEVERAGE_FAILURE,
  CREATE_BEVERAGE_SUCCESS,
  FETCH_BEVERAGE_PURCHASE_PRICES_LOOKUP,
  FETCH_BEVERAGE_PURCHASE_PRICES_LOOKUP_FAILURE,
  FETCH_BEVERAGE_PURCHASE_PRICES_LOOKUP_SUCCESS,
  SEED_BEVERAGES,
  SEED_BEVERAGES_FAILURE,
  SEED_BEVERAGES_SUCCESS,
  SELECT_BEVERAGE,
  REFETCH_BEVERAGE,
  REFETCH_BEVERAGE_COMPLETE,
} from './constants';

// Create
export const adminCreateBeverageAction = (beverage, options = {}) => ({
  beverage,
  options,
  type: ADMIN_CREATE_BEVERAGE,
});

export const createBeverageAction = (beverage, options = {}) => ({
  beverage,
  options,
  type: CREATE_BEVERAGE,
});

export const createBeverageFailure = (error) => ({
  error,
  type: CREATE_BEVERAGE_FAILURE,
});

export const createBeverageSuccess = (newBeverageName) => ({
  newBeverageName,
  type: CREATE_BEVERAGE_SUCCESS,
});

// Edit
export const adminEditBeverageAction = (beverage, options = {}) => ({
  beverage,
  options,
  type: ADMIN_EDIT_BEVERAGE,
});

export const adminEditBeverageFailure = (error) => ({
  error,
  type: ADMIN_EDIT_BEVERAGE_FAILURE,
});

export const adminEditBeverageSuccess = (beverage) => ({
  beverage,
  type: ADMIN_EDIT_BEVERAGE_SUCCESS,
});

// Misc
export const fetchBeveragePurchasePricesLookupAction = (beverageID) => ({
  beverageID,
  type: FETCH_BEVERAGE_PURCHASE_PRICES_LOOKUP,
});

export const fetchBeveragePurchasePricesLookupFailure = (error) => ({
  error,
  type: FETCH_BEVERAGE_PURCHASE_PRICES_LOOKUP_FAILURE,
});

export const fetchBeveragePurchasePricesLookupSuccess = (beveragePurchasePricesLookup = {}) => ({
  beveragePurchasePricesLookup,
  type: FETCH_BEVERAGE_PURCHASE_PRICES_LOOKUP_SUCCESS,
});

export const seedBeveragesAction = () => ({
  type: SEED_BEVERAGES,
});

export const seedBeveragesFailure = (error) => ({
  error,
  type: SEED_BEVERAGES_FAILURE,
});

export const seedBeveragesSuccess = () => ({
  type: SEED_BEVERAGES_SUCCESS,
});

export const selectBeverageAction = (id) => ({
  id,
  type: SELECT_BEVERAGE,
});
export const refetchBeverage = () => ({
  type: REFETCH_BEVERAGE,
});
export const refetchBeverageComplete = () => ({
  type: REFETCH_BEVERAGE_COMPLETE,
});
