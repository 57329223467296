export const CREATE_ACCOUNT_BEVERAGE = 'AccountBeverage/CREATE_ACCOUNT_BEVERAGE';
export const CREATE_ACCOUNT_BEVERAGE_FAILURE = 'AccountBeverage/CREATE_ACCOUNT_BEVERAGE_FAILURE';
export const CREATE_ACCOUNT_BEVERAGE_SUCCESS = 'AccountBeverage/CREATE_ACCOUNT_BEVERAGE_SUCCESS';
export const DELETE_ACCOUNT_BEVERAGE = 'AccountBeverage/DELETE_ACCOUNT_BEVERAGE';
export const DELETE_ACCOUNT_BEVERAGE_FAILURE = 'AccountBeverage/DELETE_ACCOUNT_BEVERAGE_FAILURE';
export const DELETE_ACCOUNT_BEVERAGE_SUCCESS = 'AccountBeverage/DELETE_ACCOUNT_BEVERAGE_SUCCESS';
export const FETCH_ACCOUNT_BEVERAGES = 'AccountBeverage/FETCH_ACCOUNT_BEVERAGES';
export const FETCH_ACCOUNT_BEVERAGES_FAILURE = 'AccountBeverage/FETCH_ACCOUNT_BEVERAGES_FAILURE';
export const FETCH_ACCOUNT_BEVERAGES_SUCCESS = 'AccountBeverage/FETCH_ACCOUNT_BEVERAGES_SUCCESS';
export const SELECT_ACCOUNT_BEVERAGE = 'AccountBeverage/SELECT_ACCOUNT_BEVERAGE';
export const UPDATE_ACCOUNT_BEVERAGE = 'AccountBeverage/UPDATE_ACCOUNT_BEVERAGE';
export const UPDATE_ACCOUNT_BEVERAGE_FAILURE = 'AccountBeverage/UPDATE_ACCOUNT_BEVERAGE_FAILURE';
export const UPDATE_ACCOUNT_BEVERAGE_SUCCESS = 'AccountBeverage/UPDATE_ACCOUNT_BEVERAGE_SUCCESS';
