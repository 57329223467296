export default [
  'displayName',
  'firstName',
  'lastName',
  'email',
  'emailVerified',
  'country',
  'provincialState',
  'bartender',
  'bottleCollector'];
