// Relative imports.
import {
  CREATE_BEVERAGE,
  CREATE_BEVERAGE_FAILURE,
  CREATE_BEVERAGE_SUCCESS,
  FETCH_BEVERAGE_PURCHASE_PRICES_LOOKUP,
  FETCH_BEVERAGE_PURCHASE_PRICES_LOOKUP_FAILURE,
  FETCH_BEVERAGE_PURCHASE_PRICES_LOOKUP_SUCCESS,
  SEED_BEVERAGES,
  SEED_BEVERAGES_FAILURE,
  SEED_BEVERAGES_SUCCESS,
  SELECT_BEVERAGE,
  REFETCH_BEVERAGE,
  REFETCH_BEVERAGE_COMPLETE,
} from './constants';

const initialState = {
  // Request states.
  creating: false,
  fetching: false,
  fetchingBeveragePurchasePricesLookup: false,
  hasSearched: false,
  seedingBeverages: false,
  refetchBeverage: false,
  // Data.
  beverageIDs: [],
  beveragesLookup: {},
  beveragePurchasePricesLookup: {},
  error: '',
  selectedBeverageID: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_BEVERAGE: {
      return { ...state, creating: true, error: '' };
    }
    case CREATE_BEVERAGE_FAILURE: {
      return { ...state, creating: false, error: action.error };
    }
    case CREATE_BEVERAGE_SUCCESS: {
      return { ...state, creating: false };
    }
    case FETCH_BEVERAGE_PURCHASE_PRICES_LOOKUP: {
      return {
        ...state,
        error: '',
        fetchingBeveragePurchasePricesLookup: true,
      };
    }
    case FETCH_BEVERAGE_PURCHASE_PRICES_LOOKUP_FAILURE: {
      return {
        ...state,
        error: action.error,
        fetchingBeveragePurchasePricesLookup: false,
      };
    }
    case FETCH_BEVERAGE_PURCHASE_PRICES_LOOKUP_SUCCESS: {
      return {
        ...state,
        beveragePurchasePricesLookup: action.beveragePurchasePricesLookup,
        fetchingBeveragePurchasePricesLookup: false,
      };
    }
    case SEED_BEVERAGES: {
      return { ...state, error: '', seedingBeverages: true };
    }
    case SEED_BEVERAGES_FAILURE: {
      return { ...state, error: action.error, seedingBeverages: false };
    }
    case SEED_BEVERAGES_SUCCESS: {
      return { ...state, seedingBeverages: false };
    }
    case SELECT_BEVERAGE: {
      return { ...state, selectedBeverageID: action.id };
    }
    case REFETCH_BEVERAGE: {
      return { ...state, refetchBeverage: true };
    }
    case REFETCH_BEVERAGE_COMPLETE: {
      return { ...state, refetchBeverage: false };
    }
    default: {
      return { ...state };
    }
  }
};
