// Node modules.
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import get from 'lodash/get';
// Relative imports.
import List from 'assets/react-svgs/List';
import Search from 'assets/react-svgs/Search';
import defaultUserIcon from 'assets/images/defaultUserIcon.png';
import logo from 'assets/images/logo.svg';
import { updateModalIDAction } from 'containers/Modal/actions';
import { Wrapper, MobileWrapper } from './styles';

const TopNav = ({ account, updateModalID }) => {
  const route = useLocation().pathname;

  const onLinkClick = () => {
    updateModalID();
  };

  return (
    <>
      {/* Web Nav Bar */}
      <Wrapper className='top-nav'>
        <Link onClick={onLinkClick} to='/inventory'><img alt='logo' className='logo' src={logo} /></Link>
        <div className='nav-items'>
          <Link className={route === '/inventory' ? 'selected' : ''} onClick={onLinkClick} to='/inventory'>Inventory</Link>
          <Link className={route === '/discover' ? 'selected' : ''} onClick={onLinkClick} to='/discover'>Discover</Link>
        </div>
        <Link className={`profile ${route.includes('/profile') ? 'selected' : ''}`} onClick={onLinkClick} to='/profile'>
          <img alt='account profile' src={get(account, 'imageURL', defaultUserIcon)} />
          <p>{get(account, 'firstName') || 'Profile'}</p>
        </Link>
      </Wrapper>

      {/* Mobile Nav Bar */}
      <MobileWrapper>
        <Link className={route === '/inventory' ? 'selected section' : 'section'} onClick={onLinkClick} to='/inventory'>
          <List className='list-icon' />
          <p>Inventory</p>
        </Link>

        <Link className={route === '/discover' ? 'selected section' : 'section'} onClick={onLinkClick} to='/discover'>
          <Search className='search-icon' />
          <p>Discover</p>
        </Link>

        <Link className={route === '/profile' ? 'selected section' : 'section'} onClick={onLinkClick} to='/profile'>
          <img alt='account profile' src={get(account, 'imageURL', defaultUserIcon)} />
          <p>Profile</p>
        </Link>
      </MobileWrapper>
    </>
  );
};

TopNav.propTypes = {
  // From mapStateToProps.
  account: PropTypes.shape({
    firstName: PropTypes.string,
    imageURL: PropTypes.string,
  }),
  // From mapDispatchToProps.
  updateModalID: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  account: state.accountReducer.account,
});

const mapDispatchToProps = (dispatch) => ({
  updateModalID: (id) => dispatch(updateModalIDAction(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopNav);
