// Node modules.
import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
// Relative imports.
import Spinner from 'components/Spinner';
import TopNav from 'components/TopNav';
import { Wrapper } from './styles';

const LoadingPage = ({ account }) => (
  <Wrapper showTopNav={!isEmpty(account)}>
    <TopNav />
    <Spinner />
  </Wrapper>
);

LoadingPage.propTypes = {
  // From mapStateToProps.
  account: PropTypes.object,
};

const mapStateToProps = (state) => ({
  account: state.accountReducer.account,
});

export default connect(mapStateToProps, null)(LoadingPage);
