// Node modules.
import { all } from 'redux-saga/effects';
// Relative imports.
import authMiddleware from 'containers/Auth/middleware';
import beveragesMiddleware from 'containers/Beverages/middleware';
import registerMiddleware from 'containers/Register/middleware';
import routesMiddleware from 'containers/Routes/middleware';
import accountBeverageMiddleware from 'containers/AccountBeverage/middleware';
import accountMiddleware from 'containers/Account/middleware';

// NOTE: Current pattern taken from https://github.com/redux-saga/redux-saga/issues/160#issuecomment-308540204
export default function* rootSaga() {
  yield all([
    ...authMiddleware,
    ...beveragesMiddleware,
    ...registerMiddleware,
    ...routesMiddleware,
    ...accountBeverageMiddleware,
    ...accountMiddleware,
  ]);
}
