// Node modules.
import { all, call, put, takeLatest } from 'redux-saga/effects';
import get from 'lodash/get';
// Relative imports.
import { handleSagaError } from 'utils/sagaHelpers';
import { updateModalIDAction } from 'containers/Modal/actions';
import { SUCCESS } from 'containers/UniversalNotifications/MESSAGE_TYPES';
import { showUniversalNotificationAction } from 'containers/UniversalNotifications/actions';
import {
  CREATE_ACCOUNT_BEVERAGE,
  DELETE_ACCOUNT_BEVERAGE,
  FETCH_ACCOUNT_BEVERAGES,
  UPDATE_ACCOUNT_BEVERAGE,
} from './constants';
import {
  createAccountBeverageApi,
  deleteAccountBeverageApi,
  fetchAccountBeverageApi,
  updateAccountBeverageApi,
} from './api';
import {
  createAccountBeverageFailure,
  createAccountBeverageSuccess,
  deleteAccountBeverageFailure,
  deleteAccountBeverageSuccess,
  fetchAccountBeveragesAction,
  fetchAccountBeveragesFailure,
  fetchAccountBeveragesSuccess,
  updateAccountBeverageFailure,
  updateAccountBeverageSuccess,
} from './actions';

export function* createAccountBeverageSaga({ accountBeverage }) {
  try {
    yield call(createAccountBeverageApi, accountBeverage);
    yield all([
      put(createAccountBeverageSuccess(accountBeverage)),
      put(
        showUniversalNotificationAction({
          message: 'Successfully added to inventory!',
          messageType: SUCCESS,
        }),
      ),
      put(updateModalIDAction()),
      put(fetchAccountBeveragesAction()),
    ]);
  } catch (error) {
    yield call(handleSagaError, error, {
      actionCreators: [createAccountBeverageFailure],
    });
  }
}

// @WARNING: DELETE FUNCTIONALITY NOT CURRENTLY USED in favor of `deleted: true/false`.
export function* deleteAccountBeverageSaga({ accountBeverageID }) {
  try {
    yield call(deleteAccountBeverageApi, accountBeverageID);
    yield put(deleteAccountBeverageSuccess(accountBeverageID));
  } catch (error) {
    yield call(handleSagaError, error, {
      actionCreators: [deleteAccountBeverageFailure],
    });
  }
}

export function* fetchAccountBeveragesSaga() {
  try {
    const [accountBeverageIDs, accountBeveragesLookup] = yield call(fetchAccountBeverageApi);
    yield put(fetchAccountBeveragesSuccess(accountBeverageIDs, accountBeveragesLookup));
  } catch (error) {
    yield call(handleSagaError, error, {
      actionCreators: [fetchAccountBeveragesFailure],
    });
  }
}

export function* updateAccountBeverageSaga({ accountBeverage }) {
  try {
    yield call(updateAccountBeverageApi, accountBeverage);
    yield all([
      put(updateAccountBeverageSuccess(accountBeverage)),
      put(
        showUniversalNotificationAction({
          message: get(accountBeverage, 'deleted')
            ? 'Successfully removed from inventory!'
            : 'Successfully updated in inventory!',
          messageType: SUCCESS,
        }),
      ),
      put(updateModalIDAction()),
      put(fetchAccountBeveragesAction()),
    ]);
  } catch (error) {
    yield call(handleSagaError, error, {
      actionCreators: [updateAccountBeverageFailure],
    });
  }
}

export default [
  takeLatest(CREATE_ACCOUNT_BEVERAGE, createAccountBeverageSaga),
  takeLatest(DELETE_ACCOUNT_BEVERAGE, deleteAccountBeverageSaga),
  takeLatest(FETCH_ACCOUNT_BEVERAGES, fetchAccountBeveragesSaga),
  takeLatest(UPDATE_ACCOUNT_BEVERAGE, updateAccountBeverageSaga),
];
