// Node modules.
import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  border-bottom: 1px solid #adc2e7;
  display: flex;
  justify-content: space-between;
  padding: 10px 35px 12px;
  width: 100%;

  @media (max-width: 900px) {
    padding: 10px 15px;
  }

  @media (max-width: 599px) {
    flex-direction: column;
  }

  .logo {
    margin: 0 50px 0 0;
    width: 33px;

    @media (max-width: 900px) {
      margin-right: 0;
    }
  }

  .nav-items {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-start;

    @media (max-width: 900px) {
      justify-content: center;
    }

    @media (max-width: 699px) {
      display: none;
    }

    a {
      padding: 12px 25px;
      margin-right: 15px;
      text-decoration: none;

      @media (max-width: 900px) {
        font-size: 0.9rem;
        padding: 7px 15px;
        margin-right: 0;
      }

      &.selected {
        background: #89f7c5;
        border-radius: 5px;
        color: #0a183a;
        opacity: 1;
      }
    }
  }

  .profile {
    align-items: center;
    display: flex;
    justify-content: flex-end;

    @media (max-width: 699px) {
      display: none;
    }

    img {
      border-radius: 100%;
      border: 1px solid #c1d0ea;
      height: 35px;
      margin-right: 10px;
      width: 35px;

      @media (max-width: 599px) {
        display: none;
      }
    }
  }
`;

export const MobileWrapper = styled.div`
  align-items: center;
  background: #061833;
  bottom: 0;
  display: flex;
  height: 75px;
  justify-content: space-around;
  position: fixed;
  width: 100%;
  z-index: 6;

  p {
    color: #ffffff;
    font-size: .8rem;
    font-family: 'hk_groteskmedium'
    margin-top: 5px;
  }

  @media (min-width: 700px) {
    display: none;
  }

  .section {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    justify-content: center;

  }


  img {
    border-radius: 100%;
    border: 1px solid #c1d0ea;
    height: 25px;
    margin-bottom: 2px;
    width: 25px;
  }

  .list-icon {
    fill: #ffffff;
    height: 25px;
    margin-bottom: 2px;
    width: 25px;
  }

  .search-icon {
    fill: #ffffff;
    height: 22px;
    margin-bottom: 5px;
    width: 22px;
  }

  .selected {

    .search-icon {
      fill:  #97F6CF;
    }
    .list-icon {
      fill:  #97F6CF;
    }
    p {
      color: #97F6CF;
    }
    img {
      border: 1px solid #97F6CF;
    }
  }


`;
