// Node modules.
import 'firebase/compat/firestore';
import firebase from 'firebase/compat/app';
import chunk from 'lodash/chunk';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import map from 'lodash/map';
import mean from 'lodash/mean';
import pick from 'lodash/pick';
import reduce from 'lodash/reduce';
import uuidv4 from 'uuid/v4';
// Relative imports.
import SEED_BEVERAGES from 'containers/Beverages/SEED_BEVERAGES.json';
import { deriveIDsAndLookupFromSnapshot } from 'utils';
import { DEFAULT_IMAGE_URL } from 'utils/constants/DEFAULTS';
import { PENDING, ACTIVE } from './STATUSES';
import ADMIN_VALID_FIELDS from './ADMIN_VALID_FIELDS';

const MAX_BATCH_COMMIT_SIZE = 248;

const DEFAULT_MIN_STATE_PRICES = {
  AL: { 750: null },
  AK: { 750: null },
  AZ: { 750: null },
  AR: { 750: null },
  CA: { 750: null },
  CO: { 750: null },
  CT: { 750: null },
  DE: { 750: null },
  FL: { 750: null },
  GA: { 750: null },
  HI: { 750: null },
  ID: { 750: null },
  IL: { 750: null },
  IN: { 750: null },
  IA: { 750: null },
  KS: { 750: null },
  KY: { 750: null },
  LA: { 750: null },
  ME: { 750: null },
  MD: { 750: null },
  MA: { 750: null },
  MI: { 750: null },
  MN: { 750: null },
  MS: { 750: null },
  MO: { 750: null },
  MT: { 750: null },
  NE: { 750: null },
  NV: { 750: null },
  NH: { 750: null },
  NJ: { 750: null },
  NM: { 750: null },
  NY: { 750: null },
  NC: { 750: null },
  ND: { 750: null },
  OH: { 750: null },
  OK: { 750: null },
  OR: { 750: null },
  PA: { 750: null },
  RI: { 750: null },
  SC: { 750: null },
  SD: { 750: null },
  TN: { 750: null },
  TX: { 750: null },
  UT: { 750: null },
  VT: { 750: null },
  VA: { 750: null },
  WA: { 750: null },
  WV: { 750: null },
  WI: { 750: null },
  WY: { 750: null },
};

export const adminCreateBeverageApi = async (beverage) => {
  // Derive the firebase auth.
  const auth = firebase.auth();

  // Derive the current user properties.
  const currentUser = get(auth, 'currentUser');
  const accountID = get(currentUser, 'uid');

  // Derive the firebase db.
  const db = firebase.firestore();

  // Create a unique ID.
  const beverageID = get(beverage, 'id') || uuidv4();

  // Derive beverage properties.
  const imageURL = get(beverage, 'imageURL', '');
  const distilleryName = get(beverage, 'distillery.name') || '';
  const name = get(beverage, 'name') || '';
  const yearsAged = get(beverage, 'yearsAged') ? parseInt(get(beverage, 'yearsAged'), 10) : '';
  const proof = get(beverage, 'proof') ? parseFloat(get(beverage, 'proof')).toFixed(2) : '';
  const mashBill = get(beverage, 'mashBill') || '';
  const vintage = get(beverage, 'vintage') || '';
  const category = get(beverage, 'category') || '';
  const subcategory = get(beverage, 'subcategory') || '';
  const fullAddress = get(beverage, 'distillery.fullAddress') || '';
  const city = get(beverage, 'distillery.city') || '';
  const country = get(beverage, 'distillery.country') || '';
  const state = get(beverage, 'distillery.state') || '';
  const parentName = get(beverage, 'distillery.parentName') || '';
  const distilleryDescription = get(beverage, 'distillery.description') || '';
  const websiteURL = get(beverage, 'distillery.websiteURL') || '';
  const description = get(beverage, 'description') || '';
  const review = get(beverage, 'review') || '';
  const rating = get(beverage, 'rating') || 0.0;
  const usaStateMinPriceCentsByVolumeML =
    get(beverage, 'usaStateMinPriceCentsByVolumeML') || DEFAULT_MIN_STATE_PRICES;

  // Attempt to upload the imageURL.
  const uploadedImageURL = await uploadImageApi(imageURL, beverageID);

  // Create the server timestamp.
  const timestamp = firebase.firestore.FieldValue.serverTimestamp();

  // Derive the new beverage.
  const newBeverage = {
    accountID,
    deleted: false,
    createdAt: timestamp,
    updatedAt: timestamp,
    distillery: {
      name: distilleryName,
      fullAddress,
      city,
      country,
      state,
      parentName,
      description: distilleryDescription,
      websiteURL,
    },
    name,
    id: beverageID,
    imageURL: DEFAULT_IMAGE_URL,
    yearsAged,
    proof,
    mashBill,
    vintage: vintage.substr(0, 4), // Just need the year.
    description,
    review,
    category,
    subcategory,
    rating,
    status: ACTIVE,
    usaStateMinPriceCentsByVolumeML,
  };

  // Add the uploaded imageURL if provided.
  if (uploadedImageURL) {
    newBeverage.imageURL = uploadedImageURL;
  }

  // Create the beverage.
  await db.collection('beverages').doc(beverageID).set(newBeverage);

  // Return back the new beverage.
  return newBeverage;
};

export const createBeverageApi = async (beverage) => {
  // Derive the firebase auth.
  const auth = firebase.auth();

  // Derive the current user properties.
  const currentUser = get(auth, 'currentUser');
  const accountID = get(currentUser, 'uid');

  // Derive the firebase db.
  const db = firebase.firestore();

  // Create a unique ID.
  const beverageID = get(beverage, 'id') || uuidv4();

  // Derive beverage properties.
  const imageURL = get(beverage, 'imageURL', '');
  const distilleryName = get(beverage, 'distillery.name') || '';
  const name = get(beverage, 'name') || '';
  const yearsAged = get(beverage, 'yearsAged') ? parseInt(get(beverage, 'yearsAged'), 10) : '';
  const proof = get(beverage, 'proof') || '';
  const mashBill = get(beverage, 'mashBill') || '';
  const vintage = get(beverage, 'vintage') || '';
  const category = get(beverage, 'category') || '';
  const subcategory = get(beverage, 'subcategory') || '';

  // Attempt to upload the imageURL.
  const uploadedImageURL = await uploadImageApi(imageURL, beverageID);

  // Create the server timestamp.
  const timestamp = firebase.firestore.FieldValue.serverTimestamp();

  // Derive the new beverage.
  const newBeverage = {
    accountID,
    deleted: false,
    createdAt: timestamp,
    updatedAt: timestamp,
    distillery: {
      name: distilleryName,
      fullAddress: '',
      city: '',
      country: '',
      state: '',
      parentName: '',
      description: '',
      websiteURL: '',
    },
    name,
    id: beverageID,
    imageURL: DEFAULT_IMAGE_URL,
    yearsAged,
    proof,
    mashBill,
    vintage: vintage.substr(0, 4), // Just need the year.
    description: '',
    review: '',
    category,
    subcategory,
    status: PENDING,
    usaStateMinPriceCentsByVolumeML: DEFAULT_MIN_STATE_PRICES,
  };

  // Add the uploaded imageURL if provided.
  if (uploadedImageURL) {
    newBeverage.imageURL = uploadedImageURL;
  }
  // Create the beverage.
  await db.collection('beverages').doc(beverageID).set(newBeverage);

  // Return back the new beverage.
  return newBeverage;
};

export const adminEditBeverageApi = async (beverage) => {
  // Derive the accountBeverage's id.
  const id = get(beverage, 'id');

  // Escape early if there is no id.
  if (!id) {
    throw new Error('Cannot update beverage without knowing the beverageID.');
  }

  // Derive the firebase db.
  const db = firebase.firestore();

  // Attempt to upload the imageURL.
  const imageURL = get(beverage, 'imageURL');
  const accountBeverageID = get(beverage, 'id');
  const uploadedImageURL = await uploadImageApi(imageURL, accountBeverageID);

  // Derive the server timestamp.
  const timestamp = firebase.firestore.FieldValue.serverTimestamp();

  // Whitelist kv pairs from accountBeverage to avoid adding unwanted kv pairs.
  const cleanedBeverage = pick(beverage, ADMIN_VALID_FIELDS);

  // Add the uploaded imageURL if provided.
  if (uploadedImageURL) {
    cleanedBeverage.imageURL = uploadedImageURL;
  }

  // Update the document.
  await db
    .collection('beverages')
    .doc(id)
    .update({ ...cleanedBeverage, updatedAt: timestamp });
};

export const fetchBeveragePurchasePricesLookupApi = async (beverageID) => {
  // Derive the firebase db.
  const db = firebase.firestore();

  const accountBeveragesSnapshot = await db
    .collection('accountBeverages')
    .where('deleted', '==', false)
    .where('beverageID', '==', beverageID)
    .get();

  // eslint-disable-next-line
  const [accountBeverageIDs, accountBeveragesLookup] = deriveIDsAndLookupFromSnapshot(
    accountBeveragesSnapshot.docs,
  );

  const beveragePurchasePricesLookup = reduce(
    accountBeveragesLookup,
    (beveragePurchasePrices, accountBeverage) => {
      // Derive account beverage properties.
      const purchasePriceCents = get(accountBeverage, 'purchasePriceCents');
      const volumeML = get(accountBeverage, 'volumeML');

      if (purchasePriceCents) {
        // Derive the new purchase prices array.
        const purchasePricesCents = [
          ...get(beveragePurchasePrices, `[${volumeML}].purchasePricesCents`, []),
          purchasePriceCents,
        ];

        // Update the purchase prices for the specific volume.
        beveragePurchasePrices[volumeML] = {
          ...get(beveragePurchasePrices, `[${volumeML}]`, {}),
          average: mean(purchasePricesCents),
          purchasePricesCents,
          totalPurchases: purchasePricesCents.length,
        };
      }

      return beveragePurchasePrices;
    },
    {},
  );

  return beveragePurchasePricesLookup;
};

export const seedBeveragesApi = async () => {
  // Derive the firebase auth.
  const auth = firebase.auth();

  // Derive the current user properties.
  const currentUser = get(auth, 'currentUser');
  const accountID = get(currentUser, 'uid');

  // Derive the firebase db.
  const db = firebase.firestore();

  // Firestore can only commit 500 records at a time, so split the seed data into chunks.
  const beverageChunks = chunk(SEED_BEVERAGES, MAX_BATCH_COMMIT_SIZE);
  const batches = [];

  forEach(beverageChunks, (beverageChunk) => {
    // Create a batch for all create beverage requests.
    const batch = db.batch();

    forEach(beverageChunk, (beverage) => {
      // Create a unique ID.
      const beverageID = get(beverage, 'id') || uuidv4();

      // Create the server timestamp.
      const timestamp = firebase.firestore.FieldValue.serverTimestamp();

      // Derive the new beverage.
      const newBeverage = {
        ...beverage,
        accountID,
        createdAt: timestamp,
        deleted: false,
        id: beverageID,
        updatedAt: timestamp,
        type: 'seed',
        imageURL:
          get(beverage, 'imageURL') ||
          'https://firebasestorage.googleapis.com/v0/b/homebar-web--dev.appspot.com/o/beverageImages%2FdefaultBeverage.png?alt=media&token=70fca084-402b-4958-83ab-69ef6bc20199',
      };

      // Create the beverage request reference.
      const beverageRef = db.collection('beverages').doc(beverageID);

      // Commit the beverage request.
      batch.set(beverageRef, newBeverage);
    });

    // Add batch.
    batches.push(batch);
  });

  await Promise.all(map(batches, (batch) => batch.commit()));
};

const uploadImageApi = async (imageFile, beverageID) => {
  // Escape early if there is no image URL.
  if (!imageFile) {
    return;
  }
  // Derive the firebase storage.
  const storageRef = firebase.storage().ref();

  // Derive the image ref.
  const imageRef = storageRef.child(`beverageImages/${beverageID}.jpg`);

  // Create upload task.
  await imageRef.put(imageFile, { contentType: 'image/jpeg' });

  // Fetch the downloadURL.
  const downloadURL = await imageRef.getDownloadURL();

  // Return the downloadURL.
  return downloadURL;
};
