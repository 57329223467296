// Node modules.
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import pick from 'lodash/pick';
import invoke from 'lodash/invoke';
import reduce from 'lodash/reduce';
import uuidv4 from 'uuid/v4';
// Relative imports.
import { deriveIDsAndLookupFromSnapshot } from 'utils';
import VALID_FIELDS from './VALID_FIELDS';

export const createAccountBeverageApi = async (accountBeverage) => {
  // Derive the firebase auth.
  const auth = firebase.auth();

  // Derive the current user properties.
  const currentUser = get(auth, 'currentUser');
  const accountID = get(currentUser, 'uid');

  // Derive the firebase db.
  const db = firebase.firestore();

  // Create a unique ID.
  const accountBeverageID = get(accountBeverage, 'id') || uuidv4();
  const beverageID = get(accountBeverage, 'beverageID');

  // Escape early if we do not have a beverageID or an accountID.
  if (!beverageID || !accountID) {
    const errorMessage = `You must provide ${
      !beverageID ? 'beverageID' : ''
    } and an accountID when creating an account beverage.`;

    if (window.FS) {
      window.FS.log(errorMessage, { beverageID, accountID });
    }

    throw new Error(errorMessage);
  }

  // Derive account beverage properties.
  const addedAt = get(accountBeverage, 'addedAt');
  const imageURL = get(accountBeverage, 'imageURL', '');
  const purchaseLocation = get(accountBeverage, 'purchaseLocation', '');
  const purchasePriceCents = get(accountBeverage, 'purchasePriceCents') || null;
  const quantity = get(accountBeverage, 'quantity') || 1;
  const rating = get(accountBeverage, 'rating') || null;
  const review = get(accountBeverage, 'review', '');
  const storePick = get(accountBeverage, 'storePick') || false;
  const volumeML = get(accountBeverage, 'volumeML') || 750;

  // Attempt to upload the imageURL.
  const uploadedImageURL = await uploadImageApi(imageURL, accountBeverageID);

  // Create the server timestamp.
  const timestamp = firebase.firestore.FieldValue.serverTimestamp();

  // Derive the new accountBeverage.
  const newAccountBeverage = {
    accountID,
    addedAt: addedAt ? new Date(addedAt).toISOString() : timestamp,
    beverageID,
    createdAt: timestamp,
    deleted: false,
    id: accountBeverageID,
    imageURL,
    purchaseLocation,
    purchasePriceCents,
    quantity,
    rating,
    review,
    storePick,
    updatedAt: timestamp,
    volumeML,
  };

  // Add the uploaded imageURL if provided.
  if (uploadedImageURL) {
    newAccountBeverage.imageURL = uploadedImageURL;
  }

  // Create the accountBeverage.
  await db.collection('accountBeverages').doc(accountBeverageID).set(newAccountBeverage);

  // Return back the new accountBeverage.
  return newAccountBeverage;
};

// @WARNING: DELETE FUNCTIONALITY NOT CURRENTLY USED in favor of `deleted: true/false`.
export const deleteAccountBeverageApi = async (/* accountBeverageID */) => {};

export const fetchAccountBeverageApi = async () => {
  // Derive the firebase auth.
  const auth = firebase.auth();

  // Derive the current user properties.
  const currentUser = get(auth, 'currentUser');
  const accountID = get(currentUser, 'uid');

  // Derive the firebase db.
  const db = firebase.firestore();

  // Create the snapshot.
  const accountBeveragesSnapshot = await db
    .collection('accountBeverages')
    .where('accountID', '==', accountID)
    .where('deleted', '==', false)
    .get();

  if (accountBeveragesSnapshot.empty) {
    return [[], {}];
  }

  // Get our account beverages.
  let [
    // eslint-disable-next-line
    accountBeverageIDs,
    accountBeveragesLookup,
  ] = deriveIDsAndLookupFromSnapshot(accountBeveragesSnapshot.docs);

  // Beverages has more data than account beverages, so we fetch each beverage per account beverage.
  const beverageIDs = map(accountBeveragesLookup, (accountBeverage) => accountBeverage.beverageID);
  const beveragesSnapshots = await Promise.all(
    map(beverageIDs, (beverageID) =>
      db.collection('beverages').where('id', '==', beverageID).get(),
    ),
  );
  const beverages = map(beveragesSnapshots, (beveragesSnapshot) =>
    invoke(beveragesSnapshot, 'docs[0].data'),
  );

  // Create a composite of account beverage and beverage in our lookup table.
  accountBeveragesLookup = reduce(
    accountBeveragesLookup,
    (accumulator = {}, accountBeverage, accountBeverageID) => {
      accumulator[accountBeverageID] = {
        ...accountBeverage,
        beverage: find(beverages, ['id', accountBeverage.beverageID]),
      };

      return accumulator;
    },
    {},
  );

  return [accountBeverageIDs, accountBeveragesLookup];
};

export const updateAccountBeverageApi = async (accountBeverage) => {
  // Derive the accountBeverage's id.
  const id = get(accountBeverage, 'id');

  // Escape early if there is no id.
  if (!id) {
    throw new Error('Cannot update accountBeverage without knowing the accountBeverageID.');
  }

  // Derive the firebase db.
  const db = firebase.firestore();

  // Attempt to upload the imageURL.
  const imageURL = get(accountBeverage, 'imageURL');
  const accountBeverageID = get(accountBeverage, 'id');
  const uploadedImageURL = await uploadImageApi(imageURL, accountBeverageID);

  // Derive the server timestamp.
  const timestamp = firebase.firestore.FieldValue.serverTimestamp();

  // Whitelist kv pairs from accountBeverage to avoid adding unwanted kv pairs.
  const cleanedAccountBeverage = pick(accountBeverage, VALID_FIELDS);

  // Add the uploaded imageURL if provided.
  if (uploadedImageURL) {
    cleanedAccountBeverage.imageURL = uploadedImageURL;
  }

  // Update the document.
  await db
    .collection('accountBeverages')
    .doc(id)
    .update({ ...cleanedAccountBeverage, updatedAt: timestamp });
};

const uploadImageApi = async (imageFile, accountBeverageID) => {
  // Escape early if there is no image URL.
  if (!imageFile) {
    return;
  }
  // Derive the firebase storage.
  const storageRef = firebase.storage().ref();

  // Derive the image ref.
  const imageRef = storageRef.child(`accountBeverageImages/${accountBeverageID}.jpg`);

  // Create upload task.
  await imageRef.put(imageFile, { contentType: 'image/jpeg' });

  // Fetch the downloadURL.
  const downloadURL = await imageRef.getDownloadURL();

  // Return the downloadURL.
  return downloadURL;
};
