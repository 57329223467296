// Node modules.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Relative imports.
import MESSAGE_TYPES, { ERROR, SUCCESS } from './MESSAGE_TYPES';
import { dismissUniversalNotificationAction } from './actions';
import {
  Action, CloseIcon, Message, Wrapper,
} from './styles';

class UniversalNotifications extends Component {
  static propTypes = {
    // From mapStateToProps.
    actionText: PropTypes.string,
    autoDismissAfter: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
    message: PropTypes.node.isRequired,
    messageType: PropTypes.oneOf(MESSAGE_TYPES).isRequired,
    onClickAction: PropTypes.func,
    visible: PropTypes.bool.isRequired,
    // From mapDispatchToProps.
    dismiss: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const { autoDismissAfter, visible } = props;

    // If we are already visible, make sure we dismiss after some time.
    if (visible) {
      this.setDismissTimeout(autoDismissAfter);
    }
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps) {
    // If we have become visible, make sure we dismiss after some time.
    if (nextProps.visible !== this.props.visible && nextProps.visible) {
      this.setDismissTimeout(nextProps.autoDismissAfter);
    }
  }

  componentWillUnmount() {
    this.clearDismissTimeout();
  }

  clearDismissTimeout = () => {
    if (this.dismissTimeout) {
      clearTimeout(this.dismissTimeout);
    }
  };

  deriveBackground = () => {
    switch (this.props.messageType) {
      case ERROR: {
        return '#fc1454';
      }
      case SUCCESS: {
        return '#97f6cf';
      }
      default: {
        return '#061833';
      }
    }
  };

  deriveColor = () => {
    switch (this.props.messageType) {
      case ERROR: {
        return '#ffffff';
      }
      case SUCCESS: {
        return '#081226';
      }
      default: {
        return '#081226';
      }
    }
  };

  setDismissTimeout = (autoDismissAfter) => {
    this.clearDismissTimeout();
    this.dismissTimeout = setTimeout(this.props.dismiss, autoDismissAfter);
  };

  onClickHandler = () => {
    this.clearDismissTimeout();
    this.props.dismiss();
  };

  onActionClick = () => {
    const { dispatch, onClickAction } = this.props;

    // Dispatch the onClickAction if one is provided.
    if (onClickAction) {
      dispatch(onClickAction());
    }
  };

  render() {
    const {
      deriveBackground, deriveColor, onActionClick, onClickHandler,
    } = this;
    const { actionText, message, visible } = this.props;

    // Escape early if it's not supposed to be visible.
    if (!visible) {
      return null;
    }

    return (
      <Wrapper onClick={onClickHandler} style={{ background: deriveBackground(), color: deriveColor() }}>
        {/* Message */}
        <Message data-e2e='universal-notification-message'>{message}</Message>

        {/* Action Button (if provided) */}
        {actionText && <Action onClick={onActionClick}>{actionText}</Action>}

        {/* Close Icon */}
        <CloseIcon color={deriveColor()} />
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  actionText: state.universalNotificationsReducer.actionText,
  autoDismissAfter: state.universalNotificationsReducer.autoDismissAfter,
  message: state.universalNotificationsReducer.message,
  messageType: state.universalNotificationsReducer.messageType,
  onClickAction: state.universalNotificationsReducer.onClickAction,
  visible: state.universalNotificationsReducer.visible,
});

const mapDispatchToProps = (dispatch) => ({
  // @WARNING: Normally wouldn't give the container the `dispatch` prop,
  // but this is to dispatch the onClickAction when provided.
  dispatch,
  dismiss: () => dispatch(dismissUniversalNotificationAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UniversalNotifications);
