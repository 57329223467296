import React from 'react';
import PropTypes from 'prop-types';

export default function Close(props) {
  return (
    <svg
      fill='#FFFFFF'
      xmlns='http://www.w3.org/2000/svg'
      data-name='Layer 1'
      viewBox='0 0 1024 1024'
      x='0px'
      y='0px'
      {...props}
    >
      <path
        fill={props.color || '#FFFFFF'}
        d='M602.5 512L941 173.4c25-25 25-65.5 0-90.5s-65.5-25-90.5 0L512 421.5 173.4 83c-25-25-65.5-25-90.5 0s-25 65.5 0 90.5L421.5 512 83 850.6c-25 25-25 65.5 0 90.5 12.5 12.5 28.9 18.7 45.2 18.7s32.7-6.2 45.2-18.7L512 602.5 850.6 941c25 25 65.5 25 90.5 0 12.5-12.5 18.7-28.9 18.7-45.2s-6.2-32.7-18.7-45.2L602.5 512z'
      />
    </svg>
  );
}

Close.propTypes = {
  color: PropTypes.string,
};
