// Relative imports.
import {
  CREATE_ACCOUNT_BEVERAGE,
  CREATE_ACCOUNT_BEVERAGE_FAILURE,
  CREATE_ACCOUNT_BEVERAGE_SUCCESS,
  DELETE_ACCOUNT_BEVERAGE,
  DELETE_ACCOUNT_BEVERAGE_FAILURE,
  DELETE_ACCOUNT_BEVERAGE_SUCCESS,
  FETCH_ACCOUNT_BEVERAGES,
  FETCH_ACCOUNT_BEVERAGES_FAILURE,
  FETCH_ACCOUNT_BEVERAGES_SUCCESS,
  SELECT_ACCOUNT_BEVERAGE,
  UPDATE_ACCOUNT_BEVERAGE,
  UPDATE_ACCOUNT_BEVERAGE_FAILURE,
  UPDATE_ACCOUNT_BEVERAGE_SUCCESS,
} from './constants';

const initialState = {
  // Pending states.
  creating: false,
  deleting: false,
  fetching: false,
  updating: false,
  // Data.
  accountBeverageIDs: [],
  accountBeveragesLookup: {},
  selectedAccountBeverageID: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ACCOUNT_BEVERAGE: {
      return { ...state, creating: true };
    }
    case CREATE_ACCOUNT_BEVERAGE_FAILURE: {
      return { ...state, creating: false };
    }
    case CREATE_ACCOUNT_BEVERAGE_SUCCESS: {
      return { ...state, creating: false };
    }
    case DELETE_ACCOUNT_BEVERAGE: {
      return { ...state, deleting: true };
    }
    case DELETE_ACCOUNT_BEVERAGE_FAILURE: {
      return { ...state, deleting: false };
    }
    case DELETE_ACCOUNT_BEVERAGE_SUCCESS: {
      return { ...state, deleting: false };
    }
    case FETCH_ACCOUNT_BEVERAGES: {
      return { ...state, fetching: true };
    }
    case FETCH_ACCOUNT_BEVERAGES_SUCCESS: {
      return {
        ...state, fetching: false, accountBeverageIDs: action.accountBeverageIDs, accountBeveragesLookup: action.accountBeveragesLookup,
      };
    }
    case FETCH_ACCOUNT_BEVERAGES_FAILURE: {
      return { ...state, fetching: false };
    }
    case SELECT_ACCOUNT_BEVERAGE: {
      return { ...state, selectedAccountBeverageID: action.accountBeverageID };
    }
    case UPDATE_ACCOUNT_BEVERAGE: {
      return { ...state, updating: true };
    }
    case UPDATE_ACCOUNT_BEVERAGE_SUCCESS: {
      return { ...state, updating: false };
    }
    case UPDATE_ACCOUNT_BEVERAGE_FAILURE: {
      return { ...state, updating: false };
    }
    default: {
      return { ...state };
    }
  }
};
