export default [
  'accountID',
  'deleted',
  'createdAt',
  'updatedAt',
  'distillery',
  'name',
  'id',
  'imageURL',
  'yearsAged',
  'proof',
  'mashBill',
  'vintage',
  'description',
  'review',
  'category',
  'subcategory',
  'rating',
  'status',
  'usaStateMinPriceCentsByVolumeML',
];
