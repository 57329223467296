// Node modules.
import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const LoadingRoute = ({ account }) => {
  if (isEmpty(account)) {
    return <Redirect to='/login' />;
  }

  return <Redirect to='/inventory' />;
};

LoadingRoute.propTypes = {
  // From mapStateToProps.
  account: PropTypes.object,
};

const mapStateToProps = (state) => ({
  account: state.accountReducer.account,
});

export default connect(mapStateToProps, null)(LoadingRoute);
