// Node modules.
import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
// Relative imports.
import { createAccountAction } from 'containers/Account/actions';
import { handleSagaError } from 'utils/sagaHelpers';
import { registerApi } from './api';
import { REGISTER } from './constants';
import { registerFailure, registerSuccess } from './actions';

export function* registerSaga({ account }) {
  try {
    yield call(registerApi, account);
    yield all([put(createAccountAction(account)), put(registerSuccess())]);
  } catch (error) {
    yield call(handleSagaError, error, { actionCreators: [registerFailure] });
  }
}

export default [takeLatest(REGISTER, registerSaga)];
