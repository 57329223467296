// Relative imports.
import {
  CREATE_ACCOUNT_BEVERAGE,
  CREATE_ACCOUNT_BEVERAGE_FAILURE,
  CREATE_ACCOUNT_BEVERAGE_SUCCESS,
  DELETE_ACCOUNT_BEVERAGE,
  DELETE_ACCOUNT_BEVERAGE_FAILURE,
  DELETE_ACCOUNT_BEVERAGE_SUCCESS,
  FETCH_ACCOUNT_BEVERAGES,
  FETCH_ACCOUNT_BEVERAGES_FAILURE,
  FETCH_ACCOUNT_BEVERAGES_SUCCESS,
  SELECT_ACCOUNT_BEVERAGE,
  UPDATE_ACCOUNT_BEVERAGE,
  UPDATE_ACCOUNT_BEVERAGE_FAILURE,
  UPDATE_ACCOUNT_BEVERAGE_SUCCESS,
} from './constants';

// Create
export const createAccountBeverageAction = (accountBeverage) => ({
  accountBeverage,
  type: CREATE_ACCOUNT_BEVERAGE,
});

export const createAccountBeverageFailure = (error) => ({
  error,
  type: CREATE_ACCOUNT_BEVERAGE_FAILURE,
});

export const createAccountBeverageSuccess = (accountBeverage) => ({
  accountBeverage,
  type: CREATE_ACCOUNT_BEVERAGE_SUCCESS,
});

// Delete
// @WARNING: DELETE FUNCTIONALITY NOT CURRENTLY USED in favor of `deleted: true/false`.
export const deleteAccountBeverageAction = (accountBeverageID) => ({
  accountBeverageID,
  type: DELETE_ACCOUNT_BEVERAGE,
});

export const deleteAccountBeverageFailure = (error) => ({
  error,
  type: DELETE_ACCOUNT_BEVERAGE_FAILURE,
});

export const deleteAccountBeverageSuccess = (accountBeverageID) => ({
  accountBeverageID,
  type: DELETE_ACCOUNT_BEVERAGE_SUCCESS,
});

// Fetch
export const fetchAccountBeveragesAction = () => ({
  type: FETCH_ACCOUNT_BEVERAGES,
});

export const fetchAccountBeveragesFailure = (error) => ({
  error,
  type: FETCH_ACCOUNT_BEVERAGES_FAILURE,
});

export const fetchAccountBeveragesSuccess = (accountBeverageIDs, accountBeveragesLookup) => ({
  accountBeverageIDs,
  accountBeveragesLookup,
  type: FETCH_ACCOUNT_BEVERAGES_SUCCESS,
});

// Update
export const updateAccountBeverageAction = (accountBeverage) => ({
  accountBeverage,
  type: UPDATE_ACCOUNT_BEVERAGE,
});

export const updateAccountBeverageFailure = (error) => ({
  error,
  type: UPDATE_ACCOUNT_BEVERAGE_FAILURE,
});

export const updateAccountBeverageSuccess = (accountBeverage) => ({
  accountBeverage,
  type: UPDATE_ACCOUNT_BEVERAGE_SUCCESS,
});

// Misc
export const selectAccountBeverageAction = (accountBeverageID) => ({
  accountBeverageID,
  type: SELECT_ACCOUNT_BEVERAGE,
});
