// Node modules.
import get from 'lodash/get';
import invoke from 'lodash/invoke';
import includes from 'lodash/includes';
// Relative imports.
import { notifySlack } from 'utils/slack';

const KPI_ACTION_TYPES = [
  'Account/CREATE_ACCOUNT_SUCCESS',
  'Account/UPDATE_ACCOUNT_SUCCESS',
  'Beverages/CREATE_BEVERAGE_SUCCESS',
  'Beverages/SEED_BEVERAGES_SUCCESS',
  'AccountBeverage/CREATE_ACCOUNT_BEVERAGE_SUCCESS',
  'AccountBeverage/DELETE_ACCOUNT_BEVERAGE_SUCCESS',
  'AccountBeverage/UPDATE_ACCOUNT_BEVERAGE_SUCCESS',
];

const deriveAccount = (state) => {
  // Derive the account properties.
  const account = get(state, 'accountReducer.account');
  const id = get(account, 'id');
  const firstName = get(account, 'firstName');
  const lastName = get(account, 'lastName');
  const formattedAccount = account ? `${firstName} ${lastName} (${id})` : 'Someone';

  return formattedAccount;
};

const deriveSessionURL = () => {
  // Derive the FS session URL.
  const sessionURL = invoke(window, 'FS.getCurrentSessionURL', true);
  const formattedSessionURL = sessionURL
    ? `\n\n<${sessionURL}|Click here> to start reviewing it on Full Story.`
    : '';
  return formattedSessionURL;
};

const derivePrettifiedAction = (action) => {
  let prettifiedAction = `Failed to prettify action: ${action}`;

  try {
    prettifiedAction = JSON.stringify(action, null, 2);
  } catch (error) {
    console.error('Failed to prettify action in customLogger', error, action);
  }

  return `\n\n\`\`\`${prettifiedAction}\`\`\``;
};

const handleFailureAction = (store, action) => {
  const state = store.getState();

  // Derive the slack message.
  const slackMessage = `${deriveAccount(
    state,
  )} just had a failed action that looked like this:${derivePrettifiedAction(
    action,
  )}${deriveSessionURL()}`;

  // Attempt to notify slack.
  notifySlack(slackMessage, '#web-app-errors');
};

const handleKPIAction = (store, action) => {
  const state = store.getState();

  // Derive the slack message.
  const slackMessage = `${deriveAccount(state)} just did this!${derivePrettifiedAction(
    action,
  )}${deriveSessionURL()}`;

  // Attempt to notify slack.
  notifySlack(slackMessage, '#web-app-wins');
};

export default (store) => (next) => (action) => {
  const actionType = get(action, 'type');

  const isFailureAction = includes(actionType, 'FAILURE');
  const isKPIAction = includes(KPI_ACTION_TYPES, actionType);

  if (window.location.origin === 'http://localhost:3000') {
    return next(action);
  }

  if (isFailureAction) {
    handleFailureAction(store, action);
  }

  if (isKPIAction) {
    handleKPIAction(store, action);
  }

  return next(action);
};
