// Node modules.
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import first from 'lodash/first';
import get from 'lodash/get';
import invoke from 'lodash/invoke';
import last from 'lodash/last';
import pick from 'lodash/pick';
import words from 'lodash/words';
// Relative imports.
import { USER } from './ROLES';
import VALID_FIELDS from './VALID_FIELDS';

export const createAccountApi = async (additionalProperties = {}) => {
  // Derive the firebase auth.
  const auth = firebase.auth();
  // Derive the current user properties.
  const bartender = get(additionalProperties, 'bartender', false);
  const bottleCollector = get(additionalProperties, 'bottleCollector', false);
  const country = get(additionalProperties, 'country', '');
  const currentUser = get(auth, 'currentUser');
  const displayName = get(currentUser, 'displayName');
  const email = get(currentUser, 'email', '');
  const emailVerified = get(currentUser, 'emailVerified', false);
  const firstName = get(additionalProperties, 'firstName', first(words(displayName)) || '');
  const id = get(currentUser, 'uid');
  const imageURL = get(currentUser, 'photoURL');
  const lastName = get(additionalProperties, 'lastName', last(words(displayName)) || '');
  const provincialState = get(additionalProperties, 'provincialState', '');

  // Derive the firebase db.
  const db = firebase.firestore();

  // Create the server timestamp.
  const timestamp = firebase.firestore.FieldValue.serverTimestamp();

  // Derive the new account.
  const newAccount = {
    bartender,
    bottleCollector,
    country,
    createdAt: timestamp,
    displayName,
    email,
    emailVerified,
    firstName,
    id,
    imageURL,
    lastName,
    provincialState,
    role: USER,
    updatedAt: timestamp,
  };

  // Create the snapshot.
  await db.collection('accounts').doc(id).set(newAccount);

  return newAccount;
};

export const getAccountApi = async () => {
  // Derive the firebase auth.
  const auth = firebase.auth();

  // Derive the user properties.
  const currentUser = get(auth, 'currentUser');
  const accountID = get(currentUser, 'uid');
  const emailVerified = get(currentUser, 'emailVerified');

  // Escape early if there is no accountID.
  if (!accountID) {
    if (window.FS) {
      window.FS.log('warn', 'No accountID found in firebase auth.');
    }
    return;
  }

  // Derive the firebase db.
  const db = firebase.firestore();

  // Get the account snapshot.
  const accountSnapshot = await db.collection('accounts').doc(accountID).get();

  // Return back the account object.
  const account = invoke(accountSnapshot, 'data');

  // Update the email verified state on our account.
  if (emailVerified && emailVerified !== get(account, 'emailVerified')) {
    await updateAccountApi({ emailVerified: true });
    return { ...account, emailVerified };
  }

  return account;
};

export const updateAccountApi = async (account) => {
  // Derive the firebase db.
  const db = firebase.firestore();

  // Derive the firebase auth.
  const auth = firebase.auth();

  // Derive the accountID.
  const accountID = get(auth, 'currentUser.uid');

  // Derive the imageURL.
  const imageURL = get(account, 'imageURL');

  // Attempt to upload the imageURL.
  const uploadedImageURL = await uploadImageApi(imageURL, accountID);

  // Derive the server timestamp.
  const timestamp = firebase.firestore.FieldValue.serverTimestamp();

  // Whitelist kv pairs from account to avoid adding unwanted kv pairs.
  const sanitizedAccount = pick(account, VALID_FIELDS);

  // Derive the updated account object.
  const updatedAccount = { ...sanitizedAccount, updatedAt: timestamp };

  // Add the uploaded imageURL if provided.
  if (uploadedImageURL) {
    updatedAccount.imageURL = uploadedImageURL;
  }

  // Update the document.
  await db.collection('accounts').doc(accountID).update(updatedAccount);

  // Get the account snapshot.
  const accountSnapshot = await db.collection('accounts').doc(accountID).get();

  // Return back the account object.
  return invoke(accountSnapshot, 'data');
};

const uploadImageApi = async (imageFile, accountID) => {
  // Escape early if there is no image URL.
  if (!imageFile) {
    return;
  }
  // Derive the firebase storage.
  const storageRef = firebase.storage().ref();

  // Derive the image ref.
  const imageRef = storageRef.child(`profileImages/${accountID}-profile.jpg`);

  // Create upload task.
  await imageRef.put(imageFile, { contentType: 'image/jpeg' });

  // Fetch the downloadURL.
  const downloadURL = await imageRef.getDownloadURL();

  // Return the downloadURL.
  return downloadURL;
};
